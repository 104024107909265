<template>
  <RRSheader />
  <div>
    <page-title :refresh="fetchProposals" :in_submission="in_submission">
      <template v-slot:titlePrefix>Your assigned</template>
      <template v-slot:mainTitle> proposals</template>
    </page-title>
    <!-- loading -->
    <div class="spinner" v-if="proposalsList.length == 0 && in_submission">
      <div class="d-flex justify-content-center text-primary">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <!-- page content -->
    <div class="container-fluid">
      <!-- no research has been found message -->
      <div
        class="row justify-content-center"
        v-if="proposalsList.length == 0 && !in_submission"
      >
        <div class="col-auto text-center mt-5 pt-5">
          <img
            src="/img/oc-looking-for-answers.jpg"
            class="w-25"
            alt="no researches"
          />
          <p class="text-muted fw-bold fs-5 pt-5 text-center">
            There's no proposals has been assigned to you please contact your
            committee.
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                fill="#8c98a4"
              ></path>
              <path
                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="#8c98a4"
              ></path>
              <path
                opacity="0.3"
                d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="#2670b6"
              ></path>
            </svg>
          </p>
        </div>
      </div>
      <!-- researches list section -->
      <div
        class="row mt-2 mb-3 px-5 pb-5"
        v-if="proposalsList.length > 0 || proposalsList != null"
      >
        <!-- End Form Filters -->
        <div
          class="col-sm-12 col-md-4 col-lg-3"
          v-for="research in proposalsList"
          :key="research.id"
        >
          <!-- research card component-->
          <research-card :research="research" :key="research.id" />
        </div>

        <!-- Pagination -->
        <pagination
          :links="researchesLinks"
          :perPage="researchesPerPage"
          :total="totalResearches"
          :fetchNext="fetchProposals"
          :inSubmission="in_submission"
        />
      </div>
    </div>
  </div>
  <popUpMessage />
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import pageTitle from "@/components/pageTitle";
import store from "@/store/index.js";
import RRSheader from "@/components/RRSheader";
import researchCard from "@/components/researchCard";
import pagination from "@/components/pagination";
import { hasRole } from "@/includes/helpers.js";

export default {
  components: { pageTitle, RRSheader, researchCard, pagination },
  name: "myAssignedProposals",
  data() {
    return {
      proposalsList: [],
      in_submission: false,
      researchesLinks: [],
      totalResearches: 0,
      researchesPerPage: 0,
    };
  },
  methods: {
    async fetchProposals({ page = 1 }) {
      this.in_submission = true;
      await axiosConfig
        .post(`api/myAssginedProposals?page=${page}`, {})
        .then((res) => {
          this.proposalsList = res.data.data;
          this.researchesLinks = res.data.links;
          this.totalResearches = res.data.total;
          this.researchesPerPage = res.data.per_page;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
          return;
        });

      this.in_submission = false;
    },
    hasRole,
  },
  async created() {
    await this.fetchProposals({});
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else if (!hasRole(["REC-member", "RSC-member"])) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}
</style>
